import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import aws4 from 'aws4';
import users from '../server_backend/users.json';
import './index.css';

const paymentStatusEnum = {
  0: 'Pendente',
  1: 'Pago',
  2: 'Cancelado',
  3: 'Reembolsado'
};

const orderStatusEnum = {
  0: 'Cancelado',
  1: 'Completo',
  2: 'Em Andamento'
};

function UpdateOrder() {
  const [orderId, setOrderId] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [userType, setUserType] = useState(null); // Inicializa como null
  const [orderStatus, setOrderStatus] = useState('');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const user = users.find((u) => u.email === userEmail);
    
    if (user) {
      setUserType(user.type); // Define o tipo de usuário
    } else {
      navigate('/login'); // Redirecionar se não encontrar o usuário
    }
  }, [navigate]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!orderId) {
      alert('Order ID é obrigatório.');
      return;
    }
    if (!paymentStatus && !orderStatus) {
      alert('Preencha ao menos um dos campos: Payment Status ou Order Status.');
      return;
    }

    let queryParams = '';

    if (!paymentStatus) {
      queryParams = new URLSearchParams({
        id: orderId,
        orderStatus: orderStatus || '',
      }).toString();
    } else if (!orderStatus) {
      queryParams = new URLSearchParams({
        id: orderId,
        paymentStatus: paymentStatus || ''
      }).toString();
    } else {
      queryParams = new URLSearchParams({
        id: orderId,
        paymentStatus: paymentStatus || '',
        orderStatus: orderStatus || '',
      }).toString();
    }

    const opts = {
      host: 'jw8bdx2212.execute-api.us-east-2.amazonaws.com',
      path: `/prd/v1/Orders/UpdateStatus?${queryParams}`,
      method: 'POST',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}) // Corpo vazio para a solicitação POST
    };

    aws4.sign(opts, {
      accessKeyId: 'AKIAQLVQQYILWV5UJN56',
      secretAccessKey: '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'
    });

    // Remover cabeçalhos não permitidos pelo navegador
    delete opts.headers['Host'];
    delete opts.headers['Content-Length'];

    try {
      const response = await axios({
        url: `https://${opts.host}${opts.path}`,
        method: opts.method,
        headers: opts.headers,
        data: opts.body // Passar o corpo da solicitação
      });

      if (response.status === 200) {
        alert('Status do pedido atualizado com sucesso.');
      } else {
        alert(`Falha ao atualizar status do pedido: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      alert('Erro ao atualizar status do pedido. Verifique o console para mais detalhes.');
    }
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      {!isDrawerOpen && (
        <button className="drawer-toggle" onClick={handleDrawerToggle}>
          <span className="menu-icon">&#9776;</span>
        </button>
      )}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
          <li><a href="/auth-inicial">Home</a></li>
          <li><a href="/update-order">Atualizar Status</a></li>
          <li><a href="/search-order">Informações de Pedidos</a></li>
          <li><a href="/" onClick={handleLogout}>Logout</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <div className="UpdateOrder-box">
          <h1>Atualizar Pedido</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Order ID:</label>
              <input
                type="text"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            {userType === 'admin' && ( // Verifica se o userType é 'admin'
              <div className="form-group">
                <label>Status de Pagamento:</label>
                <select
                  value={paymentStatus}
                  onChange={(e) => setPaymentStatus(e.target.value)}
                >
                  <option value="">Selecione</option>
                  {Object.entries(paymentStatusEnum).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="form-group">
              <label>Status do Pedido:</label>
              <select
                value={orderStatus}
                onChange={(e) => setOrderStatus(e.target.value)}
              >
                <option value="">Selecione</option>
                {Object.entries(orderStatusEnum).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <button type="submit" className="update-button">Atualizar Pedido</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateOrder;
